<template>

<div class="card">
    <div class="header-text">{{ title }}</div>


    <div class="chart">
      <vc-donut
          :sections="sections"
      >
      <div class="chartText">
        <span>{{this.mostUsedPercentage.toFixed(2)}}%</span>
        <p>{{ this.labels[0][0]}}</p>
      </div>
      </vc-donut>
    </div>

    <hr>

    <div class="legends">
      <ul>
        <li v-for="(label, index) in labels" v-bind:key="label[0]">

          <div class="chartValueComponent" :style="cssVars" v-bind:class="`color-${index}`">
            <span>{{ label[1] }}</span>
          </div>

          <div class="chartLabelTitle" >
            <span class="dot" :style="cssVars" v-bind:class="`color-${index}`"></span>
            <p>{{ label[0] }}</p>

          </div>
        </li>
      </ul>
    </div>



  </div>
</template>

<script>
import Vue from 'vue'
import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';
Vue.use(Donut);

export default {

  props: {
    title: { type: String, required: true },
    labels: {type: [Array], required: true},
    colors: {type: [Array], required: true},
  },



  data() {
    return {
      sections: this.sectionsConstruct(),
      sumChartValues: this.sumChartLabels(),
      mostUsedPercentage: this.calculateMostUsedPercentage(),
    };

  },


  methods: {

    sumChartLabels(){
      let sum = 0
      this.labels.map((label) => {
        sum += label[1]
      })
      return sum
    },

    calculateMostUsedPercentage(){
      let sumLabels = 0
      this.labels.map((label) => {
        sumLabels += label[1]
      })
      return (this.labels[0][1]*100)/sumLabels
    },


    sectionsConstruct(){
      const chartLabels = []
      let sumLabels = 0
      this.labels.map((label) => {
        sumLabels += label[1]
      })


      for(let i = 0; i < this.labels.length; i++){
        chartLabels.push({label: this.labels[i][0], value: this.labels[i][1]*100/sumLabels, color: this.colors[i]})
      }
      return chartLabels
    },

  },

  computed: {
    cssVars() {
      return {
        '--color-0': this.colors[0],
        '--color-1': this.colors[1],
        '--color-2': this.colors[2],
        '--color-3': this.colors[3],
      }
    }
  }



};

</script>

<style lang="scss" scoped>

  .chartText span{
    color: #525C7A;
    font-size: 24px;
    font-weight: 600;
  }

  hr {
    margin: 0 auto !important;
    width: 80%;
  }

  .chart {
    margin-bottom: 6%;
  }

  .card {
    display: flex;
    width: 100%;
    height: 100%;
    border: 1px solid var(--neutral-200);
    border-radius: 8px;
    text-align: left;
    justify-content: center;
  }
  .header-text {
    font-size: 18px;
    font-weight: 700;
    color: var(--dark-blue);
    margin: 4% 8%;
  }
  @media (max-width: 1440px){
      .header-text{
        margin: 5% 5%;
        font-size: 15px;
      }
    }



    .chartLabelTitle p{
      font-size: 10pt;
      margin-top: 5%;

    }



  .legends{
    margin-top: 6%;
    align-items: left;
    justify-content: center;
    color: var(--dark-blue);
    font-size: 14px;
  }

  .legends ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    list-style: none;
    padding-left: 5%;
  }

  .legends li {
    margin: 4% 5%;
  }

  .dot {
  width: 10px;
	height: 10px;
	border-radius: 50%;
	overflow: hidden;
	float: left;
  margin-right: 5px;
  margin-top: 3px;
  }

  .legends li .chartValueComponent{
    border-radius: 12px;
    width: auto;
    max-width: 90px;
    height: 30px;
    color: #0C1D59;
    font-weight: 600;
    text-align: center;
    opacity: .8;

  }

  .legends li .chartValueComponent span{
    opacity: 1 !important;
    color: #0C1D59;
    font-size: 20px;
    vertical-align: text-top;
  }

  .color-0{
    background-color: var(--color-0);
  }

  .color-1{
    background-color: var(--color-1);
  }

  .color-2{
    background-color: var(--color-2);
  }

  .color-3{
    background-color: var(--color-3);
  }




</style>
